import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
// This import statement has to stay, else the scss isn't included
// noinspection ES6UnusedImports
import styles from "./algorithm.module.scss"
import PostList from "../../components/post_list/post_list"
import Text from "../../components/text/text"
const format = require("string-format")

class AlgorithmTemplate extends React.Component {
  render() {
    const {
      algorithm,
      posts
    } = this.props.pageContext
    const lang = algorithm.frontmatter.lang
    const general_strings = require("./../../../content/" + lang + "/strings.json")
    const implementation_strings = require("./../../../content/" + lang + "/implementations/strings.json")
    const title = format(implementation_strings.all_languages, algorithm.frontmatter.name)
    return (
      <Layout lang={algorithm.frontmatter.lang}>
        <SEO
          title={title}
          description={algorithm.excerpt}
          lang={algorithm.frontmatter.lang}
        />

        <article>
          <header>
            <h1>
              {title}
            </h1>
          </header>

          <section className="row">
            <div className="col-lg-12">
              {posts ? <PostList posts={posts} useExcerptFrom={'language'}/> : `No implementations of ${algorithm.frontmatter.name} yet.`}
            </div>
          </section>

          <section className={"row"}>
            <div className="col-lg-12">
              <h2>{implementation_strings.about_algorithm}</h2>
            </div>
            <div className="col-lg-12">
              <Text html={algorithm.html} title={algorithm.frontmatter.title}/>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default AlgorithmTemplate
